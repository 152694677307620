import {Header, HeaderMenu, HeaderMenuItem, HeaderName, HeaderNavigation} from '@carbon/react';

const NavBar = (props) => {
    return (
        <Header aria-label="IBM Platform Name">
            <HeaderName href="#" prefix="Deloitte">
                [RELS Tririga Sandbox]
            </HeaderName>
            <HeaderNavigation aria-label="">
                {/*<HeaderMenuItem href="#">Link 1</HeaderMenuItem>
                <HeaderMenuItem href="#">Link 2</HeaderMenuItem>
                <HeaderMenuItem href="#">Link 3</HeaderMenuItem>
                <HeaderMenu aria-label="Link 4" menuLinkName="Link 4">
                    <HeaderMenuItem href="#">Sub-link 1</HeaderMenuItem>
                    <HeaderMenuItem href="#">Sub-link 2</HeaderMenuItem>
                    <HeaderMenuItem href="#">Sub-link 3</HeaderMenuItem>
                </HeaderMenu>*/}
            </HeaderNavigation>
        </Header>
    );
}
export default NavBar;