import {
    Button,
    Dropdown,
    Form,
    Loading, Modal,
    Stack,
    TextArea,
    TextInput,
} from "@carbon/react";
import {useEffect, useState} from "react";

const NewRequest = (props) => {

    const [target, setTarget] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [comment, setComment] = useState('');
    const [fetchedData,setFetchedData] = useState('');
    const [createResult,setCreateResult] = useState(null);
    const [showLoading,setShowLoading] = useState(true);

    const onChangeTargetHandler = (event) => {
        setTarget(event.selectedItem.id);
    }
    const onChangeEmailHandler = (event) => {
        setEmail(event.target.value)
    }
    const onChangeFirstNameHandler = (event) => {
        setFirstName(event.target.value)
    }
    const onChangeLastNameHandler = (event) => {
        setLastName(event.target.value)
    }
    const onChangeCommentHandler = (event) => {
        setComment(event.target.value)
    }

    const onSubmitHandler = (event) => {
        console.log('On Submit Form')
        setShowLoading(true);
        event.preventDefault();
        if(target === '')
            return;
        const body = {
            envId: target,
            username: email,
            firstname: firstName,
            lastname: lastName,
            comments: comment
        }

        console.log(body)

        fetch(process.env.REACT_APP_RELS_SERVICE_CREATE_NEW_REQUEST,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }).then(response => {
            setCreateResult({'status': +response.status});
            setShowLoading(false)
        }).catch(error => setCreateResult({'status': +error.status}));
    }

    async function loadSandboxURL(){
        console.log("Loading Sandbox URL");
        const f = await fetch(process.env.REACT_APP_RELS_SERVICE_GET_ENV);
        const pD = await f.json();
        setShowLoading(false);
        setFetchedData(pD);
        setTarget(pD[0].id);
    }

    useEffect(() =>{
        loadSandboxURL();
    },[]);

    function closeRequest() {
        console.log("Closing Request!!")
        setTarget(fetchedData[0].id);
        setCreateResult(null);
        setEmail('');
        setComment('');
        setFirstName('');
        setLastName('');
    }

    return (
        <>
            {createResult != null && <Modal size={"lg"} open={true} modalHeading={createResult.status === 200 ? "Request has been created Successfully!" : "Unable to Create Request!"} primaryButtonText={"Submit"} onRequestSubmit={closeRequest} onRequestClose={closeRequest}/>}
            {showLoading && <Loading/>}
            {fetchedData.length !== 0 && <Form onSubmit={onSubmitHandler}>
                <Stack gap={7}>
                    <Dropdown
                        id="default"
                        titleText="Environment"
                        label="Select Sandbox Environment"
                        items={fetchedData}
                        itemToString={(item) => (item ? item.url : '')}
                        onChange={onChangeTargetHandler}
                        size={"lg"}
                        initialSelectedItem={fetchedData[0]}
                    />
                    <TextInput
                        helperText="Enter Email here; Email entered here will be used as username for Sandbox Environment"
                        id="test2"
                        invalidText="Invalid error message."
                        labelText="Email"
                        placeholder="abcdef@deloitte.com"
                        disabled={false} invalid={false}
                        onChange={onChangeEmailHandler}
                        value={email}
                        required
                    />
                    <TextInput
                        id="test2"
                        invalidText="Invalid error message."
                        labelText="First Name"
                        placeholder="John"
                        disabled={false} invalid={false}
                        required
                        onChange={onChangeFirstNameHandler}
                        value={firstName}
                    />
                    <TextInput
                        id="test3"
                        invalidText="Invalid error message."
                        labelText="Last Name"
                        placeholder="Doe"
                        disabled={false} invalid={false}
                        required
                        onChange={onChangeLastNameHandler}
                        value={lastName}
                    />
                    <TextArea
                        cols={50}
                        id="test5"
                        invalidText="Invalid error message."
                        labelText="Comments"
                        placeholder="Optional Comments"
                        rows={4}
                        disabled={false} invalid={false}
                        onChange={onChangeCommentHandler}
                        value={comment}
                    />
                    <Button
                        kind="primary"
                        tabIndex={0}
                        type="submit">
                        Submit
                    </Button>
                </Stack>
            </Form>}
        </>

    );
}

export default NewRequest;