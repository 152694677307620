import './App.scss';
import {Content, Theme} from "@carbon/react";
import NavBar from "./UI/NavBar";
import NewRequest from "./Components/NewRequest";

function App() {
  return (
      <>
          <Theme theme={"g100"}>
              <NavBar/>
          </Theme>
          <Content>
              <div>
                  <h2>Sandbox Request</h2>
                  <NewRequest/>
              </div>
          </Content>
      </>
  );
}

export default App;
